.block-upload-image-wrapper {
  display: flex;
}

.block-upload-audio-wrapper {
  display: flex;
}

.block-upload-video-wrapper {
  display: flex;
}

.block-upload-image {
  margin: 4px;
  display: flex;
  align-items: center;
  width: 100px;
  flex-direction: column;
  background-color: #eee;
  padding: 12px;
  cursor: pointer;
}

.block-upload-image-preview {
  width: 100px;
  height: 60px;
  object-fit: cover;
}

.block-upload-audio {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #eee;
  padding: 4px;
  cursor: pointer;
}

.block-upload-audio-preview {
  width: 70px;
  object-fit: cover;
}

.block-upload-video {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #eee;
  padding: 4px;
  justify-content: center;
  cursor: pointer;
}

.block-upload-video video {
  max-width: 300px;
  max-height: 200px;
}

.block-upload-video-preview {
  width: 70px;
  object-fit: cover;
}
