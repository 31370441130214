.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ql-editor {
  min-height: 200px !important;
  max-height: 700px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}

.audio-preview .previews > div {
  display: flex;
}

.tox-notifications-container {
  display: none;
}
